import React from 'react';
import styled from 'styled-components';
import { DeviationMessage } from 'types/DeviationTypes';
import { TxtLargeMediumDarkResp, TxtXLargeMediumDarkResp } from 'elements/new-design/Typography';
import { MarginBottom, MarginRight, MarginTop } from 'elements/distance/Margins';
import { FlexRow } from 'elements/containers/Containers';
import { GOADate } from 'utils/date/GOADate';
import { CriticalDeviation } from 'components/new-design/deviations/CriticalDeviation';
import { useI18n } from 'utils/i18n/usei18n';
import { PlannedOngoingDeviation } from 'components/new-design/deviations/PlannedOngoingDeviation';

type DeviationsSectionProps = {
  title: string;
  deviations: DeviationMessage[];
  icon?: JSX.Element;
  handleGetStationName: (stationNameShorthand: string) => string;
};

const DeviationsList = styled.ul`
  list-style: none;
`;

const DeviationDateH3 = styled.h3`
  text-align: center;
`;

export const DeviationsSection = ({
  title,
  deviations,
  icon,
  handleGetStationName,
}: DeviationsSectionProps): JSX.Element => {
  const { translate, currentLanguage } = useI18n();

  const groupByDate = (deviations) => {
    const grouped = {};

    deviations.forEach((deviation) => {
      if (deviation.affectedTrainServices[0]) {
        if (!grouped[deviation.affectedTrainServices[0].operatingDate.formatFullDateShortMonth()]) {
          grouped[deviation.affectedTrainServices[0].operatingDate.formatFullDateShortMonth()] = [];
        }
        grouped[deviation.affectedTrainServices[0].operatingDate.formatFullDateShortMonth()].push(deviation);
      } else {
        if (!grouped[deviation.fromDateTime.formatFullDateShortMonth()]) {
          grouped[deviation.fromDateTime.formatFullDateShortMonth()] = [];
        }
        grouped[deviation.fromDateTime.formatFullDateShortMonth()].push(deviation);
      }
    });

    return grouped;
  };

  const sortDeviations = (deviations) => {
    const filteredDeviations = deviations.filter((dev) => dev.untilDateTime.isAfter(new GOADate()));
    const groupedDeviations = groupByDate(filteredDeviations);

    const sortedByDate = Object.keys(groupedDeviations).sort((a, b) => {
      const splitA = a.split(' ')[1];
      const splitB = b.split(' ')[1];

      if (splitA > splitB) return 1;
      if (splitA < splitB) return -1;
      return 0;
    });

    const sortedDeviations = sortedByDate.map((date) => {
      return groupedDeviations[date].sort((a, b) => {
        if (a.affectedTrainServices[0] && b.affectedTrainServices[0]) {
          if (a.affectedTrainServices[0].originDepartureTime.isAfter(b.affectedTrainServices[0].originDepartureTime))
            return -1;
          else if (
            a.affectedTrainServices[0].originDepartureTime.isBefore(b.affectedTrainServices[0].originDepartureTime)
          )
            return 1;
        } else return -1;
      });
    });

    return sortedDeviations;
  };

  const filteredSortedDeviations = sortDeviations(deviations);

  const handleDisplayingDeviationDates = (dev) => {
    if (dev[0].affectedTrainServices[0] && !dev[0].affectedTrainServices[0].operatingDate.isSameYear(new GOADate())) {
      return `${dev[0].affectedTrainServices[0].operatingDate.getYear()} ${dev[0].affectedTrainServices[0].operatingDate.formatFullDateFullMonth(
        currentLanguage,
      )}`;
    }

    return dev[0].affectedTrainServices[0]
      ? dev[0].affectedTrainServices[0].operatingDate.formatFullDateFullMonth(currentLanguage)
      : dev[0].fromDateTime.formatFullDateFullMonth(currentLanguage);
  };

  return (
    <li>
      <MarginTop margin={5.6}>
        {filteredSortedDeviations.length > 0 && (
          <MarginBottom margin={3.2}>
            <FlexRow>
              {icon ? <MarginRight margin={0.8}>{icon}</MarginRight> : null}

              <h2>
                <TxtXLargeMediumDarkResp>{title}</TxtXLargeMediumDarkResp>
              </h2>
            </FlexRow>
          </MarginBottom>
        )}

        <DeviationsList>
          {filteredSortedDeviations.map((dev: DeviationMessage[], idx: number) => {
            if (title === translate('CRITICAL_DEVIATIONS')) {
              /* Deviations are Unplanned, "Incidents" in GanOPS, it may be either Critical (has messageAlert, in GanOPS "GanWeb title") 
                 or "non critical" (no yellow triangle header on deviation box) */
              return (
                <li key={idx}>
                  <MarginTop margin={2}>
                    <MarginBottom margin={1}>
                      <DeviationDateH3>
                        <TxtLargeMediumDarkResp>{handleDisplayingDeviationDates(dev)}</TxtLargeMediumDarkResp>
                      </DeviationDateH3>
                    </MarginBottom>
                  </MarginTop>
                  {dev.map((item, index) => {
                    return (
                      <CriticalDeviation key={index} deviation={item} handleGetStationName={handleGetStationName} />
                    );
                  })}
                </li>
              );
            } else {
              /* Deviations are planned of some kind, Ongoing or Future */
              return dev.map((item, index) => <PlannedOngoingDeviation key={index} deviation={item} />);
            }
          })}
        </DeviationsList>
      </MarginTop>
    </li>
  );
};
