import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import {
  TxtDefaultBrownResp,
  TxtDefaultMediumDarkResp,
  TxtLargeBoldLightResp,
  TxtMediumBoldDarkResp,
  TxtLargeMediumDarkResp,
  TxtDefaultBoldBrownResp,
} from 'elements/new-design/Typography';
import { TxtLinkDefaultBrown } from 'elements/links/Links';
import { Config } from 'utils/Config';
import { FlexBetween, FlexBetweenFullWidth, FlexCenterCenter, FlexColumn } from 'elements/containers/Containers';
import { WarningIconWarning } from 'elements/new-design/icons/SymbolIcons';
import { MarginLeft } from 'elements/distance/Margins';
import { getTrainLineFromProducLineId } from 'utils/Helpers';

type CriticalDeviationProps = {
  deviation: any;
  handleGetStationName: (stationeNameShorthand: string) => string;
};

type StyleProps = {
  isCritical: boolean;
};

type TrainLineProps = {
  trainLine: string;
};

const DeviationContainer = styled.div`
  ${(_: StyleProps) => ''}
  margin-bottom: 2.4rem;
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
  border-radius: 2rem;
`;

const CriticalDeviationHeader = styled(FlexBetween)`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const TrainLine = styled.div`
  ${(_: TrainLineProps) => ''}
  border-top-left-radius: 2rem;
  background-color: ${(props) =>
    props.trainLine === 'F5'
      ? props.theme.newcolors.bgBlue
      : props.trainLine === 'R50'
      ? props.theme.newcolors.bgRed
      : props.theme.newcolors.bgGreen};
  padding: 0.5rem 1.5rem;
`;

const AffectedJourney = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  padding: 0.5rem 1.5rem;
`;

const DeviationText = styled(TxtDefaultMediumDarkResp)`
  padding: 1rem 1.5rem;
  align-self: flex-start;
`;

const ReadMore = styled(TxtLinkDefaultBrown)`
  align-self: end;
  padding: 0.5rem 1.5rem;
`;

const CriticalHeader = styled(CriticalDeviationHeader)`
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  justify-content: flex-start;
`;

const CriticalDescription = styled.div`
  padding: 0.5rem 1.5rem;
`;

const formatDescription = (description: string) => description.split('\n');

export const CriticalDeviation = ({ deviation, handleGetStationName }: CriticalDeviationProps): JSX.Element => {
  const { currentLanguage, translate } = useI18n();
  const affectedTrain = deviation.affectedTrainServices[0];
  const opsTrafficUrl = Config().opsTrainCardUrl;

  return (
    <DeviationContainer isCritical={deviation.messageAlert}>
      {affectedTrain ? (
        <FlexColumn>
          <CriticalDeviationHeader>
            <FlexCenterCenter>
              <TrainLine trainLine={getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}>
                <TxtLargeBoldLightResp>
                  {getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}
                </TxtLargeBoldLightResp>
              </TrainLine>
              <MarginLeft margin={1}>
                <TxtMediumBoldDarkResp>{affectedTrain?.trainNumber}</TxtMediumBoldDarkResp>
              </MarginLeft>
            </FlexCenterCenter>
          </CriticalDeviationHeader>

          <AffectedJourney>
            <FlexBetweenFullWidth>
              <TxtDefaultBoldBrownResp>{translate('FROM')}</TxtDefaultBoldBrownResp>
              <TxtDefaultBoldBrownResp>{translate('TO')}</TxtDefaultBoldBrownResp>
            </FlexBetweenFullWidth>

            <FlexBetweenFullWidth>
              <TxtLargeMediumDarkResp>
                {deviation.stationFrom || handleGetStationName(affectedTrain.origin)}
              </TxtLargeMediumDarkResp>
              <TxtLargeMediumDarkResp>
                {deviation.stationTo || handleGetStationName(affectedTrain.destination)}
              </TxtLargeMediumDarkResp>
            </FlexBetweenFullWidth>

            <FlexBetweenFullWidth>
              <TxtDefaultBrownResp>{affectedTrain.originDepartureTime.formatTime()}</TxtDefaultBrownResp>
              <TxtDefaultBrownResp>{affectedTrain.destinationArrivalTime.formatTime()}</TxtDefaultBrownResp>
            </FlexBetweenFullWidth>
          </AffectedJourney>

          <DeviationText>
            {currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en}
          </DeviationText>

          <ReadMore href={`${opsTrafficUrl}${affectedTrain.serviceId}`} target="_blank" rel="noreferrer">
            {translate('READ_MORE')}
          </ReadMore>
        </FlexColumn>
      ) : (
        <>
          <CriticalHeader>
            <WarningIconWarning width={4.5} height={4.5} />
            <DeviationText>
              {currentLanguage === 'no' ? deviation.messageAlert?.no : deviation.messageAlert?.en}
            </DeviationText>
          </CriticalHeader>

          <CriticalDescription>
            {formatDescription(
              currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en,
            ).map((str, index) => (
              <TxtDefaultMediumDarkResp key={index}>
                {str}
                <br />
              </TxtDefaultMediumDarkResp>
            ))}
          </CriticalDescription>
        </>
      )}
    </DeviationContainer>
  );
};
